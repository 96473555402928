<template>
	<main class="main" v-if="page">
		<section class="container">
			<Crumbs :data="crumbs"></Crumbs>

			<TopBlock :data="page.block1"></TopBlock>
		</section>

		<section class="main__full-box">
			<picture>
				<source media="(max-width: 1400px)" :srcset="require('@/assets/images/background/bg_mir-mobile.svg')" />
				<img class="main__full-box-bg main__full-box-bg--big" :src="page.block2.image" alt="Карта Мир" />
			</picture>
			<div class="main__full-box-content">
				<h2 class="main__title">
					{{ page.block2.title }}
				</h2>
				<p class="main__text editor" v-html="page.block2.content"></p>
			</div>
		</section>

		<section class="container">
			<div class="main__box">
				<h2 class="main__title">
					Банки-участники
				</h2>
				<ul class="bank__list">
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_sinara-bank.svg')" alt="Банк Синара" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								{{ page.block3.title }}
							</h3>
							<p class="bank__text editor" v-html="page.block3.content"></p>
							<div class="flex">
								<a href="tel:88001000600" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (800) 100 06 00
								</a>
								<a href="https://sinara.ru/chastnym-licam/karty/edinaya-sotsialnaya-karta" target="_blank"
									class="bank__link">
									<span class="icon icon--globe"></span>
									sinara.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_pochtabank.svg')" alt="Почта Банк" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								{{ page.block4.title }}
							</h3>
							<p class="bank__text editor" v-html="page.block4.content"></p>
							<div class="flex">
								<a href="tel:74955321300" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (495) 532 13 00
								</a>
								<a href="https://www.pochtabank.ru/service/debetcards/esk" target="_blank" class="bank__link">
									<span class="icon icon--globe"></span>
									pochtabank.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_vtbbank.svg')" alt="ВТБ Банк" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								{{ page.block41.title }}
							</h3>
							<p class="bank__text editor" v-html="page.block41.content"></p>
							<div class="flex">
								<a href="tel:78001002424" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (800) 100 24 24
								</a>
								<a href="https://www.vtb.ru/personal/karty/zhitelya/uralochka/" target="_blank" class="bank__link">
									<span class="icon icon--globe"></span>
									vtb.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_sovcombank.png')" alt="Совкомбанк" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								Совкомбанк
							</h3>
							<p class="bank__text editor">
								Совкомбанк — универсальный системно-значимый банк федерального масштаба.
								Банк занимает девятое место по активам в стране и третье — среди частных банков.
								В банке работают 23 тыс. сотрудников в 2,6 тыс. офисах, расположенных в тысяче населенных пунктах в 79
								субъектах Российской Федерации.
								Банк обслуживает 11 млн клиентов. С 2017 года Совкомбанк выпускает карту рассрочки «Халва»,
								по которой можно покупать товары и услуги в сети партнеров в беспроцентную рассрочку.
								В 2022 году партнерская сеть выросла до 250 тыс. магазинов.
								Держателями карты «Халва» сегодня являются свыше 10 млн человек.
							</p>
							<div class="flex">
								<a href="tel:88001000006" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (800) 100 00 06
								</a>
								<a href="https://sovcombank.ru/cards/ekzh/uralochka" target="_blank" class="bank__link">
									<span class="icon icon--globe"></span>
									sovcombank.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_ubrir.png')" alt="УБРиР" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								УБРиР (Уральский банк Реконструкции и Развития)
							</h3>
							<p class="bank__text editor">
								Публичное акционерное общество «Уральский банк реконструкции и развития»
								(УБРиР) — один из крупнейших универсальных банков страны, основан в 1990 году.
								УБРиР входит в список 30 крупнейших банков России по активам (по данным Интерфакс-ЦЭА,
								АЦ Эксперт на 01.01.2022). Центральный банк РФ 8 лет подряд включает УБРиР в число значимых
								кредитных организаций на рынке платежных услуг. Офисная сеть банка охватывает 45 регионов страны.
								Собственная банкоматная сеть вместе с банками-партнерами насчитывает более 20000 устройств.
							</p>
							<div class="flex">
								<a href="tel:88001000200" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (800) 1000-200
								</a>
								<a href="https://www.ubrr.ru/chastnym-klientam/bankovskie-karty/debetovaya-karta-uralochka"
									target="_blank" class="bank__link">
									<span class="icon icon--globe"></span>
									ubrr.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_rshb.png')" alt="Россельхозбанк" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								АО "Россельхозбанк"
							</h3>
							<p class="bank__text editor">
								АО "Россельхозбанк" - один из крупнейших банков в России. Сегодня это универсальный банк,
								предоставляющий все виды банковских услуг и занимающий лидирующие позиции в финансировании
								агропромышленного комплекса России. Банк работает в городах: Серов, Нижний Тагил, Первоуральск,
								Каменск-Уральский, Камышлов, Талица, Новоуральск, Реж, Алапаевск, Березовский, Верхняя Пышма и
								Екатеринбург.
								Банк предлагает жителям Екатеринбурга и Свердловской области широкую линейку потребительских кредитов,
								вкладов, карт, возможность оформления "Сельской ипотеки".
							</p>
							<div class="flex">
								<a href="tel:88001000100" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (800) 1000-100
								</a>
								<a href="https://www.rshb.ru/natural/debetcards/uralochka" target="_blank" class="bank__link">
									<span class="icon icon--globe"></span>
									rshb.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img :src="require('@/assets/images/logos/logo_gpb.png')" alt="Газпромбанк" class="bank__logo" />
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								Газпромбанк
							</h3>
							<p class="bank__text editor">
								Газпромбанк входит в тройку лидеров банковской отрасли России, а также в число крупнейших
								финансовых институтов Центральной и Восточной Европы. Предоставляет широкий спектр услуг
								корпоративным и частным клиентам, обслуживает ключевые отрасли российской экономики, а также
								предлагает современные, высокотехнологичные розничные продукты и сервисы. В числе его клиентов –
								более 4 млн физических и 92 тыс. юридических лиц.
							</p>
							<div class="flex">
								<a href="tel:88001000701" class="bank__link">
									<span class="icon icon--phone"></span>
									8 (800) 100 07 01
								</a>
								<a href="https://www.gazprombank.ru/personal/cards/7416419/" target="_blank" class="bank__link">
									<span class="icon icon--globe"></span>
									gazprombank.ru
								</a>
							</div>
						</div>
					</li>
					<li class="bank__item">
						<div class="bank__logo-box">
							<img
								:src="require('@/assets/images/logos/logo_sber.png')"
								alt="СберБанк"
								class="bank__logo"
							/>
						</div>
						<div class="bank__content">
							<h3 class="bank__title">
								СберБанк
							</h3>
							<p class="bank__text editor">
								СберБанк — крупнейший универсальный банк России и Восточной Европы.
								Банковские услуги для физических лиц. Кредиты и вклады, ипотека, дебетовые и кредитные карты и другие
								банковские услуги для частных клиентов.
							</p>
							<div class="flex">
								<a href="tel:900" class="bank__link">
									<span class="icon icon--phone"></span>
									900
								</a>
								<a
									href="http://sberbank.ru"
									target="_blank"
									class="bank__link"
								>
									<span class="icon icon--globe"></span>
									sberbank.ru
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import TopBlock from '@/components/TopBlock.vue'
import Axios from 'axios'
export default {
	name: 'Capabilities',

	components: {
		Crumbs,
		TopBlock,
	},

	data() {
		return {
			crumbs: null,
			data: null,
			page: null,
		}
	},

	mounted() {
		let self = this
		this.$nextTick(() => {
			let query = {
				page: self.$route.fullPath,
				block: self.$options.name,
			}
			let alias = query.page.split('/')
			alias = alias[alias.length - 1]

			Axios({
				url: `${process.env.VUE_APP_API_VERSION}/page/${alias}`,
				method: 'GET',
			})
				.then(response => {
					self.page = response.data
					self.page.block1.animationBg = require('@/assets/images/animation/animation_circle-pattern.svg')
					self.page.block1.animationFront = require('@/assets/images/icons/icon_estimated.svg')
					self.page.block1.animationClassBg = 'animation-pattern'
					self.page.block1.animationClassFront = 'animation-icon'
					self.page.block1.listClass = 'editor--list-column-2'
				})
				.catch(err => {
					console.log(err)
				})

			self.crumbs = [
				{
					link: 'Capabilities',
					text: 'Возможности',
				},
				{
					link: null,
					text: 'Расчетное приложение',
				},
			]
		})
		/*
		this.data = {
			title: 'Расчетное приложение',
			text:
				'Вы всегда будете в курсе предоставленных льгот и пособий (информациядоступна владельцу карты в личном кабинете). Для перевода уже существующих выплат по мерам социальной поддержки на Карту, граждане льготных категорий могут обратиться в МФЦ, предоставив реквизиты своего нового банковского счета.',
			subTitle: 'Держатель ЕСК может оформить/перенести перевод на счет карты',
			list: {
				data: ['Заработной платы', 'Пособий', 'Пенсий', 'Стипендий'],
			},
			animationBg: require('@/assets/images/animation/animation_circle-pattern.svg'),
			animationFront: require('@/assets/images/icons/icon_estimated.svg'),
			animationClassBg: 'animation-pattern',
			animationClassFront: 'animation-icon',
		}
		*/
	},
}
</script>
